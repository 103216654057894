const burger = document.querySelector(".burger");
const navNavigation = document.querySelector(".navNavigation");

burger.addEventListener("click", () => {
    burger.classList.toggle("active");
    navNavigation.classList.toggle("active");
}) 

document.querySelectorAll(".navLink").forEach(n => n.
addEventListener("click", () => {
    navNavigation.classList.remove("active");
    burger.classList.remove("active");
        
}))

